.floor-buttons {
  display: flex;
  gap: 8px;
  padding-top: 2.5vh;
  padding-right: 22px;
  padding-left: 22px;
  font-size: 1vw;
}

.floor-button {
  border-radius: 35px;
  padding: 5px 15px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  color: #929098;
  font-size: 1em;
  font-weight: 500;
  font-family: "Inter";
  height: 5.5vh;
}

/* .ant-drawer-header {
  background: #fff;
  border-bottom: none;
} */

.custom-select .ant-select-selector {
  height: 8vh; /* Adjust the height as needed */
  line-height: 8vh; /* Ensure text is centered vertically */
}

.custom-select .ant-select-selection-item {
  line-height: 8vh; /* Ensure selected item text is centered */
  border-radius: 3px;
}

.custom-select .ant-select-arrow {
  height: 8vh; /* Ensure arrow is vertically centered */
}

.floor-button.selected {
  background-color: #2f3856;
  color: white;
  border: 1px solid #2f3856;
  font-size: 1em;
  font-weight: 500;
  font-family: "Inter";
}

.tables-container {
  padding: 20px;
  position: relative;
}

.tables-container-category {
  padding: 2vh 1.5vw;
}

/* .table-card {
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #fff;
  font-size: 1vw;
}

.table-card.OPN {
  background-color: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  height: 17vh;
}

.table-card.RES {
  background-color: #ffbf00;
  color: #0f0718;
  height: 17vh;
}

.table-card.OCU {
  background-color: #008000;
  color: #0f0718;
  height: 17vh;
} */

.table-card {
  border-radius: 15px;
  text-align: center;
  padding: 20px;
  color: #fff;
  width: 20.5vw;
  font-size: 1vw;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
}

.table-card.OPN {
  background-color: rgba(146, 144, 152, 0.5);
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-card.RES {
  background-color: #ffbf00;
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-card.OCU {
  background-color: #008000;
  color: #0f0718;
  height: 17vh;
  border-radius: 10px;
}

.table-title {
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  margin-top: 1vh;
}

.table-status {
  font-size: 1em;
  font-weight: 500;
  color: #0f0718;
}

.search-input {
  border-radius: 20px;
  padding: 5px 10px;
  margin-top: 2.5vh;
  /* margin-left: 1vw; */
  font-size: 1em !important;
}

.search-input .ant-input-prefix {
  margin-right: 0.75vw;
}


.productSearch-sm .ant-input-prefix {
  margin-right: 0.8vw;
}

.productSearch-sm {
  font-size: 1vw;
}

.productSearch-sm .ant-input,
.productSearch-sm .ant-input::placeholder {
  font-size: 1em !important;
  font-weight: 500 !important;
  font-family: Inter;
  color: #929098;
}

.status-indicator span {
  position: relative;
  padding-left: 25px;
  margin-right: 20px;
  line-height: 20px;
  font-size: 1em;
  font-weight: 500;
  color: #0f0718;
  font-family: "Inter";
}

.status-indicator span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.available::before {
  background-color: rgba(146, 144, 152, 0.5); /* Light grey for Available */
}

.reserved::before {
  background-color: #ffbf00; /* Yellow for Reserved */
}

.checked-in::before {
  background-color: #008000; /* Green for Checked-in */
}

.image-container {
  position: relative;
  width: 100%;
  height: auto;
}

.image-container img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to   bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0,   0.7)); /* Adjust opacity as needed */
  z-index: -1; /* Place the gradient behind the content */
}

.amt-dial-btn-restaurant {
  width: 100%;
  height: 11vh;
  border-radius: 10px;
  background: #92909880;
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: none;
  font-size: 2em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}

.amt-dial-btn-restaurant-cash-management{
  width: 8vw;
  height: 11vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}

.amt-dial-btn-restaurant-cash-management-cancel-discount{
  width: 8vw;
  height: 11vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718 !important;
}

.amt-dial-btn-restaurant-cash-management-discount{
  width: 97%;
  height: 23.2vh;
  border-radius: 5px;
  background: rgba(146, 144, 152, 0.5);
  /* box-shadow: 0px 3px 6px #0000004c; */
  border: 1px solid #fff;
  /* font-size: 1.2vw; */
  font-size: 1.5em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
}



