.signUp.simple-keyboard.hg-layout-default .hg-button .hg-row {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}

/* reserve table styles start */
.no-persons-btns {
  width: 100%;
  height: 45px;
  border-radius: 50%;
  border: 0.5px solid #929098;
  font-size: 1.2em;
  font-weight: 500;
  color: #0f0718;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.custom-select .ant-select-selector {
  height: 6vh !important;
  border: 1px solid #fff !important;
  color: #1e1e1e;
  font-weight: 400;
  font-size: 1em !important;
}

.custom-select.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding-top: 2% !important;
}

.signUp.hg-theme-default {
  /* background-color: #fff !important; */
  border-radius: 5px;
  box-sizing: border-box;
  font-family: HelveticaNeue-Light, Helvetica Neue Light, Helvetica Neue, Helvetica, Arial, Lucida Grande, sans-serif;
  overflow: hidden;
  touch-action: manipulation;
  -webkit-user-select: none;
  user-select: none;
  width: 100%;
}
.signUp.hg-theme-default .hg-row .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-shadow: none;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.5em;
  border-radius: 0.8rem;
  font-weight: 500;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  color: #0f0718;
  width: 1.2vw !important;
}
.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url("../../../../assets/images/backspac.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40% 40%;
}

.signUp.simple-keyboard.hg-layout-default .hg-button.hg-bksp > span {
  visibility: hidden;
  width: 1.2vw;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-space {
  width: 15vw !important;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp {
  width: 1.2vw;
  background-image: url("../../../../assets/images/backspac.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30% 30%;
}

.signUp.hg-theme-default .hg-row .hg-button.hg-bksp > span {
  visibility: hidden;
  width: 1.2vw;
}

.signUP.hg-theme-default .hg-button {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0 0 3px -1px rgb(0 0 0 / 30%);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  font-size: 1.1rem;
  border-radius: 0.8rem;
  height: 3rem;
  justify-content: center;
  padding: 0px;
  margin: 0.2rem;
  width: 1.2vw !important;
}

.mainOverFlow {
  overflow: hidden;
  height: 100vh;
}

.mainRows {
  height: 96vh;
}

.productSpecImgCenter {
  align-self: center;
}

.timePicker {
  width: 100%;
}
.datePicker {
  display: flex;
}

.section-btn {
  width: 100%;
  background-color: rgb(81, 173, 154);
  border: none;
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 16%) 0px 3px 6px;
  text-align: center;
  height: 4vw;
}

.modal-btn {
  width: 48% !important;
  height: 4vw;
}

.number-buttons-container {
  display: flex;
  padding-bottom: 3vh;
  justify-content: center;
  align-items: center;
  gap: 1vw; /* Space between buttons */
  font-size: 1vw;
}

.number-button {
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000; /* Border color */
  font-size: 1.3em; /* Adjust font size as needed */
  font-weight: 500;
  color: #0f0718; /* Text color */
  background-color: #fff; /* Background color */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition */
}

.restaurantInput .ant-input::placeholder {
  color: #929098 !important;
  font-size: 1em !important;
  margin-left: -1.5vw;
  font-weight: 500;
  font-family: Inter;
}
